import { Link } from "gatsby";
import React, { useContext } from "react";
import { LanguageContext } from "../../context/language-context";
import t from "../../resources/translations";
import CTA from "../cta";
import BreadCrumb from "../layout/breadcrumb";
import Layout from "../layout/layout";
import ComparisonBlok from "../storyblok-components/comparison-blok";
import IntroCard from "../storyblok-components/intro-card";
import ParagraphBlok from "../storyblok-components/paragraph-blok";

const NostreLame = ({ data, location }) => {
  const l = useContext(LanguageContext).locale;
  return (
    <Layout location={location}>
      <BreadCrumb>
        <Link to="/">Home</Link>
        <p className="font-bold">{t.blades[l]}</p>
      </BreadCrumb>
      <h1 className="md:is-h1 is-h1m text-white mb-4">{t.blades[l]}</h1>
      <IntroCard mobmb="220px">
        <h2 className="is-h3b text-is-blue">{t.intro[l]}</h2>
        <p className="py-6">{data.intro ?? ""}</p>
      </IntroCard>
      {data.blocks.map((b) => {
        switch (b.type) {
          case "paragraph":
            return <ParagraphBlok key={b.title} b={b} />;
          case "comparison":
            return <ComparisonBlok key={b.title} b={b} />;
          default:
            return null;
        }
      })}
      <CTA />
    </Layout>
  );
};

export default NostreLame;

import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Seo from "../components/layout/seo";
import NostreLame from "../components/pages/nostre-lame";

const data = {
  intro: (
    <p>
      Impianti altamente tecnologici e l’impiego dei migliori materiali fanno si
      che le nostre lame abbiano le seguenti caratteristiche: corpo in acciaio
      di prima qualità, massima durata e dilatazione, lega trimetallica.
      <br />
      Il preciso taglio del corpo ci permette di ottenere tolleranze minime.
      <br />
      <br />
      Inoltre i corpi lama della International Saws vengono tagliati
      esclusivamente con apparecchiature laser per una massima precisione di
      taglio.
      {/* <br />
      Il laser consente inoltre di ottenere lame di spessore più sottile, cosa
      impossibile con altri metodi di produzione (ad es. stampaggio)
      <br />
      Con il laser vengono eseguiti anche gli spacchi di espansione, progettati
      per permettere alla lama di dilatarsi senza subire deformazioni dannose
      alla qualità del taglio
      <br />
      La forma ed il posizionamento degli spacchi sono studiati per contenere la
      rumorosità dell’utensile dovuta alle turbolenze d’aria create dalla
      rotazione dello stesso. */}
    </p>
  ),
  blocks: [
    {
      type: "comparison",
      title: "Corpi lama",
      text: (
        <p>
          I corpi lama della International Saws, vengono tagliati esclusivamente
          con apparecchiature laser per una massima precisione di taglio
          <br />
          <br />
          Il laser consente inoltre di ottenere lame di spessore più sottile,
          cosa impossibile con altri metodi di produzione (ad es. stampaggio)
          <br />
          <br />
          Con il laser vengono eseguiti anche gli spacchi di espansione,
          progettati per permettere alla lama di dilatarsi senza subire
          deformazioni dannose alla qualità del taglio, dovute alla forza
          centrifuga ed al calore prodotti dalla rotazione e dallo sfregamento
          con il materiale lavorato
          <br />
          <br />
          La forma ed il posizionamento degli spacchi sono studiati per
          contenere la rumorosità dell’utensile dovuta alle turbolenze d’aria
          create dalla rotazione dello stesso.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/las-is.jpg"
          layout="fullWidth"
          alt="le lame international saws vengono tagliate col laser"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/las-comp.jpg"
          layout="fullWidth"
          alt="con altri metodi è impossibile ottenere lame altrettanto sottili"
        />
      ),
    },
    {
      type: "comparison",
      title: "Equilibratura",
      text: (
        <p>
          La lama International Saws, a differenza di lame di qualità inferiore,
          viene equilibrata individualmente con attrezzature di precisione
          completamente automatizzate, per eliminare totalmente la possibilità
          di deleterie vibrazioni.
          <br />
          <br />
          Una lama non equilibrata presenta pesi diversi in diverse zone del suo
          corpo. Ciò provoca vibrazioni durante la rotazione dell’utensile.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/eq-is.jpg"
          layout="fullWidth"
          alt="international saws offre equilibratura individuale"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/eq-comp.jpg"
          layout="fullWidth"
          alt="lame non equilibrate"
        />
      ),
    },
    {
      type: "comparison",
      title: "Tensionatura",
      text: (
        <p>
          I parametri di tensionatura vengono studiati in base alle dimensioni
          della lama ed al tipo di applicazione a cui è destinata.
          <br />
          <br />
          Le lame International Saws sono dotate di un anello leggermente
          incavato di dimensioni pari a circa i 2/3 del diametro della lama e
          serve all’irrigidimento dell’utensile in rotazione.
          <br />
          <br />
          Una lama non tensionata non è stabile e quindi vibra producendo
          scheggiature sul materiale lavorato e sui taglienti.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/tens-is.jpg"
          layout="fullWidth"
          alt="international saws offre lame con anello di tensionatura"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/tens-comp.jpg"
          layout="fullWidth"
          alt="lame senza anello di tensionatura"
        />
      ),
    },
  ],
};

const LeNostreLame = ({ location }) => (
  <>
    <Seo
      title="Scopri le nostre lame"
      desc="Le lame di International Saws hanno un corpo in acciaio di lega trimetallica di prima qualità, di massima durata e dilatazione. Scopri di più!"
    />
    <NostreLame data={data} location={location} />
  </>
);

export default LeNostreLame;
